<template>
  <div
    class="relative mt-2.5"
    @mouseenter="openLangOptions = true"
    @mouseleave="openLangOptions = false"
    @click="openLangOptions = true"
  >
    <div class="pb-2.5">
      <div
        class="flex items-center cursor-pointer gap-1 rounded-full bg-black px-4 py-2 text-white"
        @click="openLangOptions = !openLangOptions"
      >
        <span v-if="loading">
          <Icon name="line-md:loading-twotone-loop" />
        </span>
        <span v-else>
          {{ currentLocale.name }}
          <Icon name="fa6-solid:chevron-down" class="mb-1" />
        </span>
      </div>
    </div>
    <div
      v-if="openLangOptions && !loading"
      class="absolute left-1/2 flex -translate-x-1/2 flex-col gap-2 rounded-2xl bg-black p-5 text-white shadow"
    >
      <div
        v-for="loc in availableLocales"
        :key="loc.code"
        class="cursor-pointer rounded-full px-4 py-2 text-center hover:bg-neutral-900"
        @click="switchLang(loc.code)"
      >
        {{ loc.name }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { locale, locales, setLocaleMessage } = useI18n()
const switchLocalePath = useSwitchLocalePath()

const availableLocales = computed(() => {
  // @ts-ignore
  return locales.value.filter((i) => i.code !== locale.value)
})

const currentLocale = computed(() => {
  // @ts-ignore
  return locales.value.filter((i) => i.code === locale.value)[0]
})

const openLangOptions = ref(false)

const loading = ref(false)

async function switchLang(loc: string) {
  if (loc === locale.value) {
    return
  }

  loading.value = true

  // @ts-ignore
  const module = await import(`../../lang/translations/${loc}.json`)

  setLocaleMessage(loc, module.default)
  locale.value = loc
  window.history.replaceState('', '', switchLocalePath(loc))

  openLangOptions.value = false

  loading.value = false
}
</script>

<style lang="scss" scoped></style>
